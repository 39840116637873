'use client'

import { init, Form } from '@feathery/react'
import { useState } from 'react'
import { Skeleton } from '@shc/ui'
import type { TypeFeatheryFormBlockFields } from '@lib/generated-types'

interface AddedTypeFeatheryFormBlockFields
  extends Omit<TypeFeatheryFormBlockFields, 'internalName' | 'paddingTop' | 'paddingBottom'> {
  SDKKey: string
}

const FeatheryForm = ({ SDKKey, formId, formCompleteUrl }: AddedTypeFeatheryFormBlockFields) => {
  const [showLoader, setShowLoader] = useState(true)

  // Initialize Feathery
  init(SDKKey)

  const skeletonLoader = (
    <div className="max-w-[768px] mx-auto">
      {[...Array(3)].map((_, index) => (
        <div key={index} className="space-y-3 pb-6">
          <Skeleton variant="rounded" height={24} width={180} />
          <Skeleton variant="rounded" height={60} width="100%" />
        </div>
      ))}
      <div className="space-y-3 pb-6">
        <Skeleton variant="rounded" height={24} width={180} />
        <Skeleton variant="rounded" height={85} width="100%" />
      </div>
      <div className="space-y-3 pb-10">
        <Skeleton variant="rounded" height={24} width="100%" />
        <Skeleton variant="rounded" height={24} width={320} />
      </div>
      <Skeleton variant="circular" height={60} width={310} className="mx-auto" />
    </div>
  )

  // Show the Feathery form
  return (
    <>
      {showLoader && skeletonLoader}
      <Form
        className="feathery-form"
        formId={formId}
        onLoad={() => setShowLoader(false)}
        onFormComplete={() => {
          location.href = formCompleteUrl ?? '/thank-you'
        }}
      />
    </>
  )
}

export default FeatheryForm
