import { setSchedulingType } from '@lib/utilities/provider-utilities'
import type { TypeProviderPublic } from '@lib/provider-types'
import type { ContentfulImagesAPI, ImageSizes } from '@components/contentful-image'
import ContentfulImage from '@components/contentful-image'
import { aspectRatioMap } from '@lib/token-maps'
import { generateBaseMediaQWidths } from '@lib/services/image-service'
import isInteger from 'lodash/isInteger'
import { dateFirstAvailableFadPromo } from '@lib/utilities/date-utilities'
import { Icon, Rating, Typography } from '@shc/ui'

const extras = generateBaseMediaQWidths(4)
const focalPoint = 'Face'
let aspectRatioVal = aspectRatioMap['1:1']
const imageSizes: ImageSizes[] | undefined = extras?.map((value) => {
  return {
    mediaQuery: value.mediaQuery,
    params: {
      w: value.w,
      h: Math.ceil(value.w / aspectRatioVal),
      fit: 'fill',
      f: focalPoint?.toLowerCase() as Lowercase<NonNullable<ContentfulImagesAPI['f']>>,
    },
  }
})

export const formatFirstAvailableForPromo = (epoch: number, type: string) => {
  const zuluString = new Date(epoch).toISOString()
  const apptDate = dateFirstAvailableFadPromo(zuluString)

  if (type === 'virtual') {
    return `${apptDate} (virtual)`
  } else {
    return `${apptDate} (in person)`
  }
}

export interface ProviderPromoCardProps {
  promoProvider: TypeProviderPublic & {
    first_available_appt_office: number
    first_available_appt_virtual: number
  }
  displayAppt: boolean
}

const ProviderPromoCard = ({ promoProvider, displayAppt }: ProviderPromoCardProps) => {
  const providerPhotoUrl = promoProvider.photo_url?.replace(/^https?:/i, '')
  const schedulingType = setSchedulingType(promoProvider)
  const isVirtualDateNumeric = isInteger(promoProvider.first_available_appt_virtual)
  const isOfficeDateNumeric = isInteger(promoProvider.first_available_appt_office)

  return (
    <div className="flex flex-col h-full items-center bg-gray-50 drop-shadow-md rounded px-4 pb-8 gap-y-6 group hover:bg-gray-75 hover:drop-shadow-lg duration-200">
      <div className="flex flex-col gap-y-1">
        <div className="mx-auto -mt-[50px] overflow-hidden object-cover w-[100px] h-[100px] border-[6px] border-gray-50 group-hover:border-gray-75 rounded-full duration-200">
          <ContentfulImage
            src={providerPhotoUrl}
            imageSizes={imageSizes}
            alt="Provider Image"
            className="group-hover:scale-[1.03] ease-in-out duration-300"
          />
        </div>
        <div className="flex text-center">
          <Typography variant="h5" className="text-primary">
            {promoProvider.name_formatted_with_degrees}
          </Typography>
        </div>
        <div className="flex justify-center">
          {promoProvider.review_rating &&
          promoProvider.review_count &&
          promoProvider.review_count > 29 ? (
            <Rating value={promoProvider.review_rating} />
          ) : (
            <div className="font-semibold text-sm">&nbsp;</div>
          )}
        </div>
        {promoProvider.places && promoProvider.places?.length > 0 ? (
          <div className="flex justify-center items-center text-sm">
            <Icon className="text-primary pr-2" icon="location-dot" />
            {promoProvider.places[0].city}
          </div>
        ) : (
          <div className="font-semibold text-sm">&nbsp;</div>
        )}
      </div>
      {displayAppt ? (
        <div className="flex flex-col gap-1 justify-start">
          {schedulingType === 'Online' ? (
            <div>
              {isVirtualDateNumeric || isOfficeDateNumeric ? (
                <div className="flex flex-col gap-y-1 justify-center">
                  <div className="flex text-sm font-semibold justify-center">First available</div>

                  {/* Virtual */}
                  {isVirtualDateNumeric && (
                    <div
                      className="flex flex-row items-center justify-center text-sm"
                      data-testid="first-available-virtual-div">
                      <Icon className="w-3.5 pr-1.5" icon="video" />
                      {formatFirstAvailableForPromo(
                        promoProvider.first_available_appt_virtual,
                        'virtual'
                      )}
                    </div>
                  )}

                  {/* In Person */}
                  {isOfficeDateNumeric && (
                    <div
                      className="flex flex-row items-center justify-center text-sm"
                      data-testid="first-available-person-div">
                      <Icon className="w-3.5 pr-1.5" icon="buildings" />
                      {formatFirstAvailableForPromo(
                        promoProvider.first_available_appt_office,
                        'in person'
                      )}
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          ) : (
            <>
              <Typography variant="small-body-semibold" as="h6" align="center">
                Appointments available
              </Typography>

              <div className="text-center text-sm">
                {promoProvider.is_providing_virtual_visits
                  ? 'In-person and virtual visits'
                  : 'In-person'}
              </div>
            </>
          )}
        </div>
      ) : null}
    </div>
  )
}

export default ProviderPromoCard
