'use client'
import clsx from 'clsx'
import { type MouseEvent, useState, useContext } from 'react'
import { ptMap, pbMap, cardWidthMap } from '@lib/token-maps'
import slugify from '@sindresorhus/slugify'
import { routeMapper } from '@lib/mappers/menu-item-mapper'
import type { TypeCardAccordionBlockFields } from '@lib/generated-types/TypeCardAccordionBlock'
import CardAccordion from '@components/card-accordion'
import { richTextRenderFactory } from '@lib/rich-text-options'
import { Button, Container } from '@shc/ui'
import Script from 'next/script'
import { mapButtonVariant } from '@lib/mappers/sharp-ui-mapper'
import useAnalytics, { type SPContext } from '@hooks/use-analytics'
import { BlockContext, PageContext } from '@lib/analytics'
import { getIconFromContentful } from '@lib/icons'
import { AnalyticsLink } from '@components/analytics'
import NextLink from 'next/link'

const CardAccordionBlock = ({
  internalName,
  sectionLink,
  cards,
  paddingTop,
  paddingBottom,
  cardAlignment = 'center',
  ...props
}: TypeCardAccordionBlockFields) => {
  const [isExpanded, setIsExpanded] = useState(cards.map(() => false))
  const { track } = useAnalytics()
  let classyCampaigns: {
    campaignId: string
    donation: { modal: { urlParams: { egfa: boolean }; elementSelector: string } }
  }[] = []

  const blockContext = useContext(BlockContext)
  const pageContext = useContext(PageContext)

  const toggleAccordion = (index: number, contexts: SPContext[]) => {
    const newState = isExpanded.slice()
    const newStateValue = !isExpanded[index]
    const lastCard = index + 1 === cards.length

    // 1up
    if (window.innerWidth < 640) {
      // Open this card only
      newState[index] = newStateValue
    }

    // 2up
    if (window.innerWidth >= 640 && window.innerWidth < 1024) {
      const mod = (index + 1) % 2

      // is card at start of row and is last card
      if (mod === 1 && lastCard) {
        newState[index] = newStateValue
      }

      // is card at start of row and is not the last card
      if (mod === 1 && !lastCard) {
        newState[index] = newStateValue
        newState[index + 1] = newStateValue
      }

      // is card at end of row
      if (mod === 0) {
        newState[index - 1] = newStateValue
        newState[index] = newStateValue
      }
    }

    // 3up
    if (window.innerWidth >= 1024) {
      const mod = (index + 1) % 3

      // is card at start of row and is last card
      if (mod === 1 && lastCard) {
        newState[index] = newStateValue
      }

      // is card at start of row and is not last card
      if (mod === 1 && !lastCard) {
        newState[index] = newStateValue
        newState[index + 1] = newStateValue
        if (index + 2 < cards.length) {
          newState[index + 2] = newStateValue
        }
      }

      // is card #2 of row and is last card
      if (mod === 2 && lastCard) {
        newState[index - 1] = newStateValue
        newState[index] = newStateValue
      }

      // is card #2 of row and is not last card
      if (mod === 2 && !lastCard) {
        newState[index - 1] = newStateValue
        newState[index] = newStateValue
        newState[index + 1] = newStateValue
      }

      if (mod === 0) {
        newState[index - 2] = newStateValue
        newState[index - 1] = newStateValue
        newState[index] = newStateValue
      }
    }

    //Set State
    setIsExpanded(newState)

    // Analytics
    track({
      event: {
        name: newStateValue ? 'component_expand' : 'component_collapse',
        data: {},
      },
      contexts,
    })
  }

  return (
    <Container
      id={typeof sectionLink === 'undefined' ? undefined : slugify(sectionLink)}
      className={clsx('grid grid-cols-12', ptMap[paddingTop], pbMap[paddingBottom])}
      {...props}>
      <div
        className={clsx(
          'col-span-12 col-start-1 flex flex-row flex-wrap gap-5',
          cardAlignment === 'center' ? 'justify-center' : 'justify-start'
        )}>
        {cards.map(({ fields, sys }, index) => {
          const accordionContentContext: SPContext = {
            name: 'content',
            data: {
              content_type: sys.contentType.sys.id,
              content_internal_name: fields.title, // cardAccordionItem has no internalName
              content_entry_id: sys.id,
              content_level: 'item',
            },
          }
          const baseContexts: SPContext[] = [accordionContentContext]
          if (pageContext) {
            baseContexts.push(pageContext)
          }
          if (blockContext) {
            baseContexts.push(blockContext)
          }

          const { route, isInternal } = routeMapper(
            fields.buttonLink,
            fields.buttonAnchorOrExternalLink
          )
          const hasClassyModal = fields.buttonModal?.includes('Classy-')

          if (hasClassyModal) {
            classyCampaigns.push({
              campaignId: fields.buttonModal?.split('-')[1] ?? '',
              donation: {
                modal: {
                  urlParams: { egfa: true },
                  elementSelector: `#classy-button-${index + 1}`,
                },
              },
            })
          }

          return (
            <CardAccordion
              key={index}
              primaryIcon={getIconFromContentful(fields.primaryIcon)}
              title={fields.title}
              summary={fields.summary}
              icon1={getIconFromContentful(fields.icon1)}
              icon1Text={fields.icon1Text}
              icon2={getIconFromContentful(fields.icon2)}
              icon2Text={fields.icon2Text}
              button={
                <AnalyticsLink
                  as={Button}
                  asPassthru={NextLink}
                  id={hasClassyModal ? `classy-button-${index + 1}` : `button-${index + 1}`}
                  href={route}
                  target={isInternal ? undefined : '_blank'}
                  rel={isInternal ? undefined : 'noopener noreferrer'}
                  width="full"
                  {...mapButtonVariant(fields.buttonStyle)}
                  onClick={(e: MouseEvent<any>) => {
                    if (hasClassyModal) {
                      e.preventDefault()
                    }
                  }}
                  snowplow={{ contexts: [accordionContentContext] }}>
                  {fields.buttonLabel}
                </AnalyticsLink>
              }
              accordionDropdownLabel={fields.accordionDropdownLabel}
              accordionBody={richTextRenderFactory()(fields.accordionBody)}
              rightFooterText={fields.rightFooterText}
              isFeatured={fields.isFeatured}
              featuredText={fields.featuredText}
              isExpanded={isExpanded[index]}
              toggleAccordion={() =>
                toggleAccordion(index, [
                  ...baseContexts,
                  { name: 'component', data: { component_text: fields.accordionDropdownLabel } },
                ])
              }
              className={cardWidthMap['3-up']}
            />
          )
        })}
      </div>

      {classyCampaigns.length > 0 && (
        <Script id="classy-script" strategy="afterInteractive">
          {`
          (function (win) {
            var campaigns = ${JSON.stringify(classyCampaigns)}

            win.egProps = {
              campaigns
            };

            win.document.body.appendChild(makeEGScript());

            // Create the embed script
            function makeEGScript() {
              var egScript = win.document.createElement("script");
              egScript.setAttribute("type", "text/javascript");
              egScript.setAttribute("async", "true");
              egScript.setAttribute(
                "src",
                "https://sdk.classy.org/embedded-giving.js"
              );

              return egScript;
            }
          })(window);
        `}
        </Script>
      )}
    </Container>
  )
}

export default CardAccordionBlock
