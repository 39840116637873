import { type ComponentPropsWithoutRef, Fragment } from 'react'
import clsx from 'clsx'
import type { Menu } from '@lib/types'
import useAnalytics from '@hooks/use-analytics'
import { Icon, Link } from '@shc/ui'

export interface BreadcrumbProps extends ComponentPropsWithoutRef<'nav'> {
  breadcrumbs: Menu
  activePathname: string | null
}

const Breadcrumbs = ({
  breadcrumbs,
  className,
  children,
  activePathname,
  ...props
}: BreadcrumbProps) => {
  const { track } = useAnalytics()
  return (
    <nav
      className={clsx(
        'h-20 container mx-auto flex flex-row flex-nowrap justify-between items-center',
        className
      )}
      aria-label="breadcrumbs"
      {...props}>
      <ol className={clsx('flex flex-row flex-nowrap gap-x-2 items-center')}>
        {breadcrumbs.map(([link], idx, arr) => (
          <Fragment key={idx}>
            {idx !== 0 && (
              <li className={clsx('inline-flex', idx !== 1 && 'hidden md:inline-flex')}>
                <Icon icon="chevron-right" className="text-primary h-[10px]" />
              </li>
            )}
            <li
              className={clsx(
                idx > 0 && idx < arr.length - 1 ? 'hidden md:inline-flex' : 'inline-flex'
              )}>
              <Link
                href={link.route}
                target={link.isInternal ? undefined : '_blank'}
                rel={link.isInternal ? undefined : 'noopener noreferrer'}
                noUnderline
                {...(link.route.toLowerCase() === activePathname ? { 'aria-current': 'page' } : {})}
                className="no-wrap text-sm"
                onClick={() => {
                  track({
                    event: {
                      name: 'navigation_click',
                      data: {
                        navigation_tree: arr
                          .slice(0, idx + 1)
                          .map(([item]) => item.name)
                          .join(' > ')
                          .trim(),
                        navigation_level: idx + 1,
                        navigation_subject: link.name,
                        navigation_url: link.route,
                      },
                    },
                    contexts: [{ name: 'section', data: { section_name: 'breadcrumb' } }],
                  })
                }}>
                {link.icon !== undefined && (
                  <Icon
                    icon={link.icon}
                    aria-hidden="false"
                    aria-label={link.name}
                    className="h-4"
                  />
                )}
                {!link.icon && link.name}
              </Link>
            </li>
          </Fragment>
        ))}
      </ol>
      <div className="empty:hidden">{children}</div>
    </nav>
  )
}

export default Breadcrumbs
