import type {
  AppointmentRule,
  EnumAppointmentType,
  EnumAppointmentTypeCode,
  TypeInsurancePlan,
  TypeProviderPlaceJoined,
  TypeProviderPublic,
  TypeProviderSpecialty,
} from '@lib/provider-types'
import { filter, partition, groupBy, some } from 'lodash'

export const getPrimarySpecialty = (
  provider: TypeProviderPublic
): TypeProviderSpecialty | undefined => {
  let primarySpecialty
  if (provider.specialties?.length) {
    primarySpecialty = provider.specialties.find((s) => s.is_primary === true)
  }
  return primarySpecialty
}

export const filterParentPlaces = (
  places?: TypeProviderPublic['places']
): TypeProviderPublic['places'] => {
  places = places?.filter(
    (place) => !some(place.tags, (tag) => tag.name === 'Hide on provider profile')
  )
  const setOfIds = new Set(places?.map((place) => place.id))
  const mapOfContainPlaces = new Map<number, number>()
  if (places) {
    let dup_id
    for (let place of places) {
      let parent_id = place?.within_places?.[0]?.id
      if (parent_id) {
        if (!mapOfContainPlaces.has(parent_id)) {
          mapOfContainPlaces.set(parent_id, 1)
        } else {
          dup_id = mapOfContainPlaces.get(parent_id)
          if (dup_id) {
            mapOfContainPlaces.set(parent_id, dup_id + 1)
          }
        }
      }
    }
  }
  const filteredPlaces: TypeProviderPublic['places'] = places?.filter(
    (place) =>
      place.within_places &&
      (place?.within_places.length === 0 || !setOfIds.has(place?.within_places[0].id))
  )

  const updatedPlaces = []
  if (filteredPlaces && filteredPlaces.length > 0) {
    for (let place of filteredPlaces) {
      if (place?.within_places?.[0]?.id) {
        place.name =
          typeof place.within_places[0].name === 'undefined' ? '' : place.within_places[0].name
        const number = mapOfContainPlaces.get(place?.within_places?.[0]?.id)
        if (number && number > 1) {
          place.slug = place.within_places[0].slug
          place.street_address = place.within_places[0].street_address
          place.secondary_address = place.within_places[0].secondary_address
          place.postal_code = place.within_places[0].postal_code
          place.phone_formatted = place.within_places[0].phone_formatted
          place.phone = place.within_places[0].phone
          place.fax_formatted = place.within_places[0].fax_formatted
          place.city = place.within_places[0].city
          place.state = place.within_places[0].state
          mapOfContainPlaces.set(place?.within_places?.[0]?.id, 0)
        } else if (number && number === 1) {
          place.slug = place.within_places[0].slug
        } else {
          continue
        }
      }
      updatedPlaces.push(place)
    }
  }
  return updatedPlaces
}

export const getSeoDescription = (provider: TypeProviderPublic): string => {
  // specialty (with final "s" removed) or empty string
  const primarySpecialty = getPrimarySpecialty(provider)?.specialty_name?.replace(/s$/, '') ?? ''
  const providerPlaceCity = provider.places?.[0]?.city ?? 'San Diego'

  const letterCaseSpecialty = /^[A-Z]{2,}/.test(primarySpecialty)
    ? primarySpecialty
    : primarySpecialty.toLowerCase()

  let seoDescription = `${provider.name_formatted} is a Sharp-affiliated ${letterCaseSpecialty} provider in ${providerPlaceCity}.`

  if (primarySpecialty.length === 0) {
    seoDescription = `Call ${provider.name_formatted}'s office to schedule an appointment today.`
  }

  return seoDescription
}

/* providerLayout
+---------------+---------------+----------------+
|service_status | is_referable  | providerLayout |
+---------------+---------------+----------------+
| Active        | TRUE          | Referable      |
| Active        | FALSE         | Non-referable  |
| Deceased      | TRUE or FALSE | Inactive       |
| Leave         | TRUE or FALSE | Inactive       |
| Resigned      | TRUE or FALSE | Inactive       |
| Retired       | TRUE or FALSE | Inactive       |
+---------------+---------------+----------------+
*/
export type TypeProviderLayout = 'Inactive' | 'Referable' | 'Non-referable'
export const setProviderLayout = ({
  service_status,
  is_referable,
}: TypeProviderPublic): TypeProviderLayout => {
  let providerLayout: TypeProviderLayout = 'Inactive' // (Leave|Resigned|Retired|Deceased)
  if (service_status === 'Active') {
    if (is_referable) {
      // provider.patient_panel_status == (Open|Closed|Restricted)
      providerLayout = 'Referable'
    } else {
      // provider.patient_panel_status == "No panel"
      providerLayout = 'Non-referable'
    }
  }
  return providerLayout
}

// There is different display and functionality throughhout the component basedd on schedulingType
// schedulingType is just a fancy handle based on two provider properties. Doing the logic once instead of many times
export const setSchedulingType = ({
  scheduling_url,
  epic_allow_open_scheduling,
}: TypeProviderPublic) => {
  if (epic_allow_open_scheduling) {
    return 'Online'
  }

  if (scheduling_url?.length) {
    return 'Third-party'
  }

  return 'Phone'
}

// return comma separated list of specialty names beyond primary care
export const getNonPrimarySpecialtyList = (provider: TypeProviderPublic): string => {
  const { specialties } = provider
  const primaryCareSpecialtyNames = ['internal medicine', 'family medicine', 'pediatrics']

  // filter out non-referable specialties
  const referableSpecialties = filter(specialties, { is_referable: true })

  // end if there are less than 2 referable specialties
  if (referableSpecialties.length < 2) {
    return ''
  }

  //  get an array of each primaryCareSpecialties and nonPrimaryCareSpecialties
  const [primaryCareSpecialties, nonPrimaryCareSpecialties] = partition(
    referableSpecialties,
    (specialty) =>
      primaryCareSpecialtyNames.includes(
        (specialty.specialty_name?.toString().toLowerCase()?.trim() ?? '').toLowerCase()
      )
  )

  // end if there are less than 1 of each of the specialty arrays
  if (primaryCareSpecialties.length < 1 || nonPrimaryCareSpecialties.length < 1) {
    return ''
  } else {
    // return comma separated list of nonPrimaryCareSpecialties.specialty_names
    return nonPrimaryCareSpecialties.map((specialty) => specialty.specialty_name).join(', ')
  }
}

/**
 * Retrieves value appointmentRule[appointment_type] e.g. ['SDV,'REG20']
 *
 * @param {AppointmentRule} appointmentRule - The appointment rules containing information about appointment types.
 * @param {EnumAppointmentType} appointment_type - The specific appointment type for which to retrieve the type codes.
 * @returns {string[]} - An array of appointment type codes corresponding to the given appointment type.
 */
export const getApptTypeCodeFromRule = (
  appointmentRule: AppointmentRule,
  appointment_type: EnumAppointmentType
): EnumAppointmentTypeCode[] | [] => {
  const result = appointmentRule[appointment_type as keyof AppointmentRule]
  if (result !== undefined) {
    return result as EnumAppointmentTypeCode[]
  }
  return []
}

/**
 * Reduces the insurance plans of a provider by grouping them based on insurer_name and insurance_plan_type_name.
 * Only the first plan from each group is kept, resulting in a reduced set of insurance plans.
 * @param {TypeProviderPublic} provider - The provider object containing the insurance plans to be reduced.
 * @returns {TypeProviderPublic} The modified provider object with the reduced insurance plans.
 */
const reduceProviderInsurancePlans = (provider: TypeProviderPublic): TypeProviderPublic => {
  // Group the insurance plans by insurer_name and insurance_plan_type_name
  const groupedInsurancePlans = groupBy(
    provider.insurance_plans,
    (plan) => `${plan.insurer_name} - ${plan.insurance_plan_type_name}`
  )

  // Create a reduced array containing the first plan from each group
  const reducedInsurancePlans: TypeInsurancePlan[] = Object.values(groupedInsurancePlans).map(
    (plans) => plans[0] // Take the first plan from each group
  )

  // Update the insurance_plans property of the provider with the reduced array
  provider.insurance_plans = reducedInsurancePlans
  return provider
}

/**
 * Reduces the schedules of each place within the provider by filtering out non-Open schedules and  grouping them by day of the week.
 * @param {TypeProviderPublic} provider - The provider object containing the places and their schedules to be reduced.
 * @returns {TypeProviderPublic} The modified provider object with the reduced schedules for each place.
 */
const reduceProviderSchedules = (provider: TypeProviderPublic) => {
  provider.places?.forEach((place: TypeProviderPlaceJoined) => {
    // Filter the schedules of the current place by type "Open"
    const filteredSchedules = place.schedules?.filter((schedule) => schedule.type === 'Open')

    // Group the filtered schedules of the current place by day of the week
    const groupedSchedules = groupBy(filteredSchedules, (schedule) => schedule.day)

    // Create an array of reduced schedules for the current place
    const reducedSchedules = Object.values(groupedSchedules).map((schedules) => schedules[0])

    // Update the schedules property of the current place with the reduced array
    place.schedules = reducedSchedules
  })

  return provider
}

/**
 * Reduces duplicate data from the API before passing it into the provider pages
 * @param {TypeProviderPublic} provider - The provider object to be transformed.
 * @returns {TypeProviderPublic} The transformed provider object with reduced insurance plans and schedules.
 */
export const transformProvider = (provider: TypeProviderPublic) => {
  let providerTransformed = reduceProviderInsurancePlans(provider)
  providerTransformed = reduceProviderSchedules(providerTransformed)

  return providerTransformed
}

const methods = {
  getNonPrimarySpecialtyList,
  getPrimarySpecialty,
  getSeoDescription,
  setProviderLayout,
  setSchedulingType,
  transformProvider,
}
export default methods
