import type { ComponentPropsWithoutRef, ReactElement, ReactNode } from 'react'
import clsx from 'clsx'
import { bgColorMap, bgImageMap } from '@lib/token-maps'
import { Container, Typography } from '@shc/ui'

export interface CtaBackgroundProps extends ComponentPropsWithoutRef<'div'> {
  title?: string
  description?: string | ReactElement | ReactNode
  background?: keyof typeof bgImageMap
  backgroundColor?: 'primary' | 'primary-50' | 'secondary' | 'secondary-50' | 'gray-50'
}

const CtaBackground = ({
  title,
  description,
  backgroundColor = 'primary',
  background,
  className,
  children,
  ...props
}: CtaBackgroundProps) => {
  return (
    <div
      className={clsx(
        'grid grid-cols-12 w-full bg-cover bg-center lg:rounded',
        bgColorMap[backgroundColor],
        background && bgImageMap[background],
        className
      )}
      {...props}>
      <Container className="col-span-12 lg:col-span-8 lg:col-start-3 flex flex-col gap-6 items-center justify-center py-12 lg:py-20">
        {typeof title !== 'undefined' && (
          <Typography
            align="center"
            className={clsx(backgroundColor === 'primary' && !background && 'text-white')}
            variant="h2">
            {title}
          </Typography>
        )}
        {typeof description !== 'undefined' && (
          <div
            className={clsx(
              'text-center',
              backgroundColor === 'primary' && !background && 'text-white'
            )}>
            {description}
          </div>
        )}
        <div className="flex flex-col md:flex-row justify-center gap-5 w-full">{children}</div>
      </Container>
    </div>
  )
}

export default CtaBackground
