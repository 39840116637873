import clsx from 'clsx'

export const campaignFilledClasses = clsx(
  'before:bg-campaign-primary',
  'hover:before:bg-campaign-primary-700',
  'text-white'
)

export const campaignOutlinedClasses = clsx(
  'before:bg-transparent',
  'before:border-campaign-primary',
  'hover:before:bg-campaign-primary',
  'text-white'
)

export const campaignLinkClasses = clsx(
  'text-campaign-tertiary-700',
  'hover:text-campaign-tertiary-800'
)
