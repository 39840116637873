'use client'
import { usePathname } from '@i18n/navigation'
import Breadcrumbs from '@components/breadcrumbs'
import type { TypeBreadcrumbBlockFields } from '@lib/generated-types'
import omit from 'lodash/omit'
import { routeMapper, menuItemMapper } from '@lib/mappers/menu-item-mapper'
import { Button } from '@shc/ui'

const BreadcrumbBlock = ({
  internalName,
  breadcrumbs,
  buttonLabel,
  buttonAnchorOrExternalLink,
  buttonLink,
  ...props
}: TypeBreadcrumbBlockFields) => {
  const link = routeMapper(buttonLink, buttonAnchorOrExternalLink)
  const breadcrumbsArr = typeof breadcrumbs !== 'undefined' ? breadcrumbs?.map(menuItemMapper) : []
  return (
    <Breadcrumbs
      breadcrumbs={breadcrumbsArr}
      activePathname={usePathname()}
      {...omit(props, ['internalName'])}>
      {buttonLabel && link.route && (
        <Button
          href={link.route}
          target={link.isInternal ? undefined : '_blank'}
          rel={link.isInternal ? undefined : 'noopener noreferrer'}
          variant="outlined"
          width="auto"
          size="xs">
          {buttonLabel}
        </Button>
      )}
    </Breadcrumbs>
  )
}

export default BreadcrumbBlock
