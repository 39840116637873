import { Typography } from '@shc/ui'
import clsx from 'clsx'
import type { ComponentPropsWithoutRef, ReactElement } from 'react'
export interface CardProps extends ComponentPropsWithoutRef<'div'> {
  title: string
  summary?: string
  image?: ReactElement
  link?: string
  linkIsInternal?: boolean
  onClick?: any
}

const Card = ({
  title,
  summary,
  image,
  link,
  linkIsInternal = true,
  onClick,
  className,
  ...props
}: CardProps) => {
  const card = (
    <article>
      {image}
      <Typography variant="h5" className={clsx('pt-4', link && 'group-hover:underline')}>
        {title}
      </Typography>
      {summary && <p className="pt-2 text-sm font-medium">{summary}</p>}
    </article>
  )
  return (
    <div className={className} {...props}>
      {link && (
        <a
          href={link}
          onClick={onClick}
          target={linkIsInternal ? undefined : '_blank'}
          rel={linkIsInternal ? undefined : 'noopener noreferrer'}
          className="outline-primary outline-offset-2 focus:outline-2">
          {card}
        </a>
      )}
      {!link && card}
    </div>
  )
}

export default Card
