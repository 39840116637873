'use client'

import { Pagination, type PaginationProps } from '@shc/ui'
import React from 'react'
import { useRouter } from '@i18n/navigation'

type ClientPaginationProps = {
  currentPageNumber: number
  total: number
  scrollTo?: string
  searchParam?: string
} & PaginationProps

function ClientPagination(props: Readonly<ClientPaginationProps>) {
  const { total, currentPageNumber, scrollTo, searchParam = 'page', ...rest } = props
  const router = useRouter()

  const current = currentPageNumber > total ? 1 : currentPageNumber

  const onPageChange = (page: number) => {
    const url = new URL(window.location.href)
    url.searchParams.set(searchParam, page.toString())
    router.push(url.toString(), { scroll: false })

    if (scrollTo) {
      const element = document.getElementById(scrollTo)
      if (element) {
        window.scrollTo({
          top: element.getBoundingClientRect().top + window.scrollY - window.scrollOffSet,
          left: 0,
          behavior: 'smooth',
        })
      }
    }
  }

  return <Pagination {...rest} current={current} total={total} onChange={onPageChange} />
}

export default ClientPagination
